.restaurantlist-wrapper {
  padding: 24px 0px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

@media (min-width: 500px) and (max-width: 969px) {
  .restaurantlist-wrapper {
    max-width: 337px;
  }
}

@media (min-width: 970px) and (max-width: 1394px) {
  .restaurantlist-wrapper {
    width: 719px;
    justify-content: space-around;
  }
}

@media (min-width: 1394px) {
  .restaurantlist-wrapper {
    width: 1060px;
  }
}
