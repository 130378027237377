.buttonformat-wrapper {
  width: 100%;

  border-radius: 16px;
  cursor: pointer;

  text-decoration: none;
}

.buttonformat-image-wrapper {
  width: 100% !important;

  position: relative;
}

.buttonformat-image {
  width: 100%;
  height: 140px;
  position: relative;

  border-radius: 16px;
}

.buttonformat-logo {
  position: absolute !important;
  bottom: 10px;
  left: 8px;
  z-index: 200;

  height: 40px;
  width: 40px;
  border-radius: 1000px;
}

.buttonformat-information-wrapper {
  margin: 10px 0px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.buttonformat-schedule-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3px;
}

@media (min-width: 500px) {
  .buttonformat-wrapper {
    max-width: 337px;
  }
}

/* @media (min-width: 970px) and (max-width: 1033px) {
  .buttonformat-wrapper {
    width: 300px;

    border-radius: 16px;
    cursor: pointer;

    text-decoration: none;
  }
} */
