.heroimg-wrapper {
  width: 100%;
  /* max-width: 337px; */

  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
}

.heroimg-title-wrapper {
  padding: 20px 16px;
  display: none;
}

.heroimg-title-wrapper h1 {
  color: white;

  padding-bottom: 8px;
  line-height: 32px;
}

.heroimg-title-wrapper h2 {
  color: #ffffff;
}

.heroimg-image-wrapper {
  width: 100%;

  display: flex;
  justify-content: center;
}

.heroimg-image {
  border-radius: 8px;
  width: 100%;
  height: 130px;
}

@media (min-width: 500px) and (max-width: 969px) {
  .heroimg-wrapper {
    max-width: 337px;
  }
}

@media (min-width: 400px) and (max-width: 970px) {
  .heroimg-image {
    border-radius: 8px;
    width: 100%;
    height: 140px;
  }
}

@media (min-width: 970px) and (max-width: 1394px) {
  .heroimg-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    max-width: 719px;
    background-color: #0d0d0d;
  }

  .heroimg-title-wrapper {
    padding: 33px 44px;
    display: block;
  }

  .heroimg-title-wrapper h1 {
    line-height: 34px;
    font-size: 32px;
  }

  .heroimg-title-wrapper h2 {
    font-size: 16px;
  }

  .heroimg-image {
    padding: 8px;
    height: 150px;
  }
}

@media (min-width: 1394px) {
  .heroimg-wrapper {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    max-width: 1060px;
    background-color: #0d0d0d;
  }

  .heroimg-title-wrapper {
    padding: 33px 44px;
    display: block;
  }

  .heroimg-title-wrapper h1 {
    line-height: 58px;
    font-size: 56px;
  }

  .heroimg-title-wrapper h2 {
    font-size: 20px;
    line-height: 22px;
  }

  .heroimg-image {
    padding: 8px;

    height: 219px;
  }
}
