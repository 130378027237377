@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;900&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

h1 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: -0.016em;
  text-align: left;
  margin: 0;
}

h2 {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-align: left;
  margin: 0;
}

h3 {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.0018em;
  text-align: left;
  margin: 0;
}

h4 {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.0018em;
  text-align: left;
  margin: 0;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.skip-to-main {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  position: static;
  width: auto;
  height: auto;
}

