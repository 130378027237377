.home-view-outter-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 24px 32px;
}

.home-div-border {
  border-top: 1px #0000001f solid;
  width: 100%;
  padding: 0px !important;

  position: absolute;
  z-index: 1000;

  top: 140px;
}

.home-view-inner-wrapper > {
  padding: 24px 36px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-view-wrapper {
  padding: 24px 0px;

  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

@media (min-width: 500px) {
  .home-div-border {
    top: 124px;
  }

  .home-view-wrapper {
    padding: 24px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 1046px) {
  .home-view-inner-wrapper {
    padding: 0px 136px;
  }

  .home-view-wrapper {
    padding: 24px 0px;
  }

  .home-div-border {
    top: 124px;
  }
}
