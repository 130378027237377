.navbar-wrapper {
  height: 92px;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1160px;

  padding-bottom: 24px;
}

.navbar-location-icon {
  display: none;
}

.navbar-title {
  font-size: 20px;
  line-height: 22px;
  margin-left: 14px;
}

.navbar-inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.navbar-logo-btn-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding-bottom: 16px;
  /* width: 100%; */
}

.navbar-button-wrapper {
  display: flex;
  align-items: center;
  background-color: #efefef;
  border-radius: 8px !important;
}

button {
  font-family: "Inter", sans-serif;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.0009em;
  font-variation-settings: "slnt" 0;

  width: 80px;
  cursor: pointer;
}

.navbar-setup-btn > *,
.navbar-delivery-btn > * {
  text-decoration: none;
  color: black;
}

.navbar-setup-btn {
  border: 1px #e1e1e1 solid;
  border-top-left-radius: 8px 8px !important;
  border-bottom-left-radius: 8px 8px !important;
  border-right: none;
}

.navbar-delivery-btn {
  border: 1px #e1e1e1 solid;
  border-top-right-radius: 8px 8px !important;
  border-bottom-right-radius: 8px 8px !important;
  border-left: none;
}

.navbar-btn-active {
  border-radius: 8px !important;
  border: 1px #e1e1e1 solid !important;
  background-color: white;
}

.navbar-logo {
  height: 32px;
}

.navbar-address-wrapper {
  font-weight: 600;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;

  text-align: center;
}

.navbar-address-information {
  display: flex;
  flex-direction: row;

  font-size: 10px !important;
}

@media (min-width: 388px) {
  .navbar-address-information {
    font-size: 14px !important;

    align-items: center;
  }
}

@media (min-width: 500px) {
  .navbar-wrapper {
    justify-content: flex-start;
  }

  .navbar-title {
    display: none;
  }

  .navbar-inner-wrapper {
    flex-direction: row;
    align-items: center;
  }

  .navbar-logo-btn-wrapper {
    padding-bottom: 0px;
    width: auto;
  }

  .navbar-address-wrapper {
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
  }

  .navbar-location-icon {
    display: block;
  }

  .navbar-logo {
    height: 76px;
  }
}

@media (min-width: 970px) {
  .navbar-wrapper {
    justify-content: flex-start;

    max-width: 1160px;
    padding-bottom: 24px;
  }

  .navbar-logo-btn-wrapper {
    display: flex;
    flex-direction: row;
    gap: 24px !important;
  }
}
